import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';
import {MenuItem} from '../../../entities/menuItems.entity';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() items = [];
  @Output() searchResults = new EventEmitter<any[]>();
  // параметр по которому будет осуществляться поиск
  @Input() valueBySearch = '';
  @Input() menuSearch = false;
  @Input() selectSearch = false;
  searchTerm = '';
  showResults = false;
  searchItems = [];
  noResult = false;
  focusInput = false;

  constructor(private navigationService: NavigationService, private authService: AuthService) { }

  ngOnInit() {}

  onSearch(event?: KeyboardEvent) {
    if (this.selectSearch) {
      this.searchLogic();
    } else {
      // если была нажата клавиша Enter
      if (event?.keyCode === 13) {
        this.showResults = false;
        this.searchLogic();
      }
    }
  }

  searchLogic() {
    if (!this.searchTerm.length) {
      this.searchResults.emit(this.items);
    } else {
      const items = this.items;
      // console.log('this.convertToFlatArray(items)', this.convertToFlatArray(items));
      this.searchResults.emit(this.convertToFlatArray(items).filter(item =>
        item[this.valueBySearch].toLowerCase().startsWith(this.searchTerm.toLowerCase())
      ));
    }
  }

  convertToFlatArray(items: any[]): any[] {
    const flatArray = [];

    items.forEach(item => {
      flatArray.push({
        checked: item.checked,
        childs: item.childs,
        id: item.id,
        opened: item.opened,
        parentId: item.parentId,
        title: item.title
      });

      if (item.childs && item.childs.length) {
        const nestedItems = this.convertToFlatArray(item.childs);
        flatArray.push(...nestedItems);
      }
    });

    return flatArray;
  }

  changeInput(event: any) {
    // console.log('event', event);
    this.noResult = false;
    if (event?.length) {
      const items = this.items;
      if (this.selectSearch) {
        //если selectSearch true эмит сразу
        this.searchResults.emit(items.filter(item =>
          item[this.valueBySearch].toLowerCase().startsWith(this.searchTerm.toLowerCase())
        ));
        this.searchItems = this.convertToFlatArray(items).filter(item =>
          item.permissions ? item[this.valueBySearch].toLowerCase().startsWith(this.searchTerm.toLowerCase()) &&
            item.permissions.includes(this.authService.getToken().role) :
            item[this.valueBySearch].toLowerCase().startsWith(this.searchTerm.toLowerCase())
        );
      } else {
        this.searchItems = items.filter(item =>
          item[this.valueBySearch].toLowerCase().startsWith(this.searchTerm.toLowerCase()) &&
          item.permissions.includes(this.authService.getToken().role)
        );
      }
      // console.log('this.searchItems', this.searchItems);
      this.searchResults.emit(this.searchItems);
      if (!this.searchItems.length) {
        this.showResults = false;
        this.noResult = true;
      } else {
        this.showResults = true;
        this.noResult = false;
      }
    } else {
      this.removeSearch();
    }
  }

  changValue(item: MenuItem) {
    if (item.dropdownItems) {
      // если у элемента есть свой выпадающий список,
      // то при клике на него переход по ссылке не требуется
      this.searchItems = [];
      this.showResults = false;
      this.searchTerm = item.name;
      const items = this.items;
      this.searchResults.emit(items.filter(elem =>
        elem[this.valueBySearch].toLowerCase().startsWith(this.searchTerm.toLowerCase())
      ));
    } else {
      this.navigationService.goTo(item.link);
      this.removeSearch();
    }
  }

  focusin() {
    this.focusInput = true;
  }

  focusout() {
    this.focusInput = false;
  }

  removeSearch() {
    this.searchTerm = '';
    this.showResults = false;
    this.noResult = false;
    this.searchItems = [];
    this.searchResults.emit(this.items);
  }
}
