import {AppService} from '../services/app.service';
import {AfterViewInit, Component, OnInit, OnDestroy} from '@angular/core';
import {NavigationService} from '../services/navigation.service';
import {CartModelService} from '../models/cart.model.service';
import {Subscription} from 'rxjs';

@Component({
  template: ''
})
export abstract class MainComponent implements AfterViewInit, OnInit, AfterViewInit, OnDestroy {

  public objectKeys = Object.keys;
  public appService: AppService;
  public navigationService: NavigationService;
  public cartModelService: CartModelService;

  protected subscriptions: Subscription[] = [];

  constructor(
    appService: AppService,
    navigationService: NavigationService,
    cartModelService: CartModelService,
  ) {

    this.appService = appService;
    this.navigationService = navigationService;
    this.cartModelService = cartModelService;

  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for ( let subscription of this.subscriptions) {
        subscription.unsubscribe();
      }

      this.subscriptions = [];
    }
  }

  ionViewDidEnter() {
  }
}
