import {Entity} from '../core/main.entity';
import {Type} from 'class-transformer';

import {CartSummary} from './cartSummary.entity';

export class CustomerCart extends Entity implements Entity {
  customerId?: string;
  orderId?: string;
  dateCreated?: string;

  @Type(() => CartSummary)
  summary: CartSummary = new CartSummary();

  getFormattedDateCreated(): string {
    return this.getFormattedDate(this.dateCreated);
  }

}
