import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {MainModelService} from '../core/mainModel.service';
import {plainToClass, plainToInstance} from 'class-transformer';
import {Entity} from './main.entity';
import {Pagination} from '../entities/pagination.entity';
import {Sort} from '../entities/sort.entity';

@Injectable({
  providedIn: 'root'
})
export class RestModelService<T extends Entity> extends MainModelService {

  entityType: any;

  constructor(
    http: HttpClient,
    appService: AppService,
    entityType: new() => T
  ) {
    super(http, appService);

    this.entityType = entityType;
  }

  getItems(page: number, limit: number, sort: any, filter: any, callback: (items: T[], pagination: any) => void, url = null, message = false, loading = true) {
    this.postRequest({
      page,
      sort,
      filter,
      limit,
    }, {}, (response) => {
      const items: any = plainToInstance(this.entityType, response.result.items);
      const pagination: any = plainToInstance(Pagination, response.result.pagination);

      callback(items, pagination);
    }, url, message, loading);
  }

  getItem(id: string, callback: (item: T) => void) {
    this.getRequest({}, (response) => {
      const item: any = plainToInstance(this.entityType, response.result.item);

      callback(item);
    }, id);
  }

  // searchItems(id: string, q: string, callback: (items: T[]) => void) {
  //   this.getRequest({q}, (response) => {
  //     const items: any = plainToInstance(this.entityType, response.result.item);
  //
  //     callback(items);
  //   }, id);
  // }

  // deleteItem(id: string, callback: (status: boolean) => void) {
  //   this.deleteRequest(null, (response) => {
  //     callback(response.status);
  //   }, id);
  // }
  //

  saveItem(id: string, item: any, successCallback: (data: T) => void, failedCallback: (info: any) => void = (info: any) => {} ) {
    const getData: any = [];

    this.putRequest(item, getData, (response) => {
      if ( response.status ) {
        successCallback(
            plainToInstance(this.entityType, response.result['item']) as any
        );
      } else {
        failedCallback(response.result);
      }
    }, id ? id : null, false);
  }
  //
  // uploadFile(file: File, callback: (data: any) => void) {
  //   const getData: any = [];
  //
  //   const formData: FormData = new FormData();
  //   formData.append('file', file, file.name);
  //
  //   this.postRequest(formData, getData, (response) => {
  //     if (response.status) {
  //       callback(response.status);
  //     }
  //   });
  //
  // }

  getSortItems() {
    return [];
  }

  getLimitItems() {
    return [
      {
        id: 20,
        title: '20',
      },
      {
        id: 50,
        title: '50',
      },
      {
        id: 100,
        title: '100',
      }
    ];
  }

  getSortObject() {
    return new Sort();
  }
}
