import {Entity} from '../core/main.entity';

export class Sort extends Entity {
  id: string;
  title: string;
  field: string;
  direction: 'asc' | 'desc' = 'asc';

  constructor(id: string = '', title: string = '', field: string = '', direction: 'asc' | 'desc' = 'asc') {
    super();

    this.id = id;
    this.title = title;
    this.field = field;
    this.direction = direction;
  }
}
