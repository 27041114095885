import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, TimeoutError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, timeout} from 'rxjs/operators';
import {SettingsService} from '../../services/settings.service';
import {AppService} from '../../services/app.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private appService: AppService,
    private navigationService: NavigationService
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // this.appService.requestTimeoutExpended = false;
    return next.handle(request).pipe(
      timeout(120000),
      catchError(err => {
        if (err instanceof TimeoutError) {
          console.error('Timeout has occurred', request.url);
          // this.appService.requestTimeoutExpended = true;
        }
        return next.handle(request);
      })
    ).pipe(
      catchError(err => {
        const errorResponse = err instanceof HttpErrorResponse;
        const unauthorizedErr = errorResponse && err.status === 401;
        const nonAuthRequested = request.url.indexOf('/auth') === -1;

        if ( nonAuthRequested && unauthorizedErr ) {
          this.navigationService.goTo('auth');
        }

        return next.handle(request);
      })
    );
  }

}
