import {Entity} from '../core/main.entity';
import {Type} from 'class-transformer';
import {CustomerGroup} from './customerGroup.entity';
import {formatDate} from '@angular/common';

export class Customer extends Entity {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  siret: string;
  companyName: string;
  dateCreated: string;

  @Type(() => CustomerGroup)
  group: CustomerGroup = new CustomerGroup();

  get getDateCreate() {
    return this.dateCreated ? new Date(this.dateCreated) : null;
  }

  get getGroupTitle() {
    return this.group?.title;
  }

  getFormattedDateCreated() {
    return this.getFormattedDate(this.dateCreated);
  }
}
