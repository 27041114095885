import {EventEmitter, Injectable} from '@angular/core';
import {MenuController, ModalController, ToastController} from '@ionic/angular';
import {AlertController} from '@ionic/angular';
import {HelperService} from './helper.service';
import {LoadingController} from '@ionic/angular';
import {AuthToken} from '../entities/authToken.entity';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  appReady = false;
  toastController: ToastController;
  menu: MenuController;
  loadingController: LoadingController;
  modalController: ModalController;
  loading: any;
  isLoading = false;
  loadingCounter = 0;
  employeeToken = '23024ff6dd6b53bebc2a65b33cc36a35';
  hideMenu = false;
  menuItems = [];
  pushData: any = null;
  user: AuthToken;

  private alertController: AlertController;

  constructor(
    menu: MenuController,
    alertController: AlertController,
    toastController: ToastController,
    loadingController: LoadingController,
    modalController: ModalController
  ) {
    this.menu = menu;
    this.alertController = alertController;
    this.toastController = toastController;
    this.loadingController = loadingController;
    this.modalController = modalController;
  }

  async showLoading() {
    this.loadingCounter++;

    // if (!this.isLoading && !this.startPage) {
    if (!this.isLoading) {

      this.isLoading = true;

      this.loading = await this.loadingController.create({
        message: '<div class="loading-wrapper">' +
          '<div class="loading-spinner">' +
          '</div>' +
          '</div>',
        cssClass: 'custom-loading',
        spinner: null,
        translucent: false,
        showBackdrop: false,
        animated: false
      });
      await this.loading.present();
    }
  }

  async hideLoading(all = false) {
    this.loadingCounter--;

    if (all) {
      this.loadingCounter = 0;
    }

    if (this.loadingCounter <= 0) {

      // Добавляем класс для анимации
      await this.loading?.classList?.add('hide-loading');
      // Оборачиваем в setTimeout на 500, чтобы перед dismiss
      // успела отработать анимация плавного скрытия лоадера
      await setTimeout(() => {
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }

        this.loadingCounter = 0;

        if (this.isLoading) {
          this.isLoading = false;
          return this.loadingController.dismiss(null, 'cancel');
        }
      }, 500);
    }

    return null;
  }

  async showAlert(title: string, desc: string, error: boolean) {
    const alert = await this.alertController.create({
      header: title,
      message: desc,
      cssClass: 'alert-modal',
      buttons: ['Ok']
    });

    await alert.present();
  }

  async presentConfirmationAlert(data): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const alert = await this.alertController.create({
        header: data.header,
        message: data.message,
        cssClass: 'alert-modal confirm-modal',
        buttons: [
          {
            text: 'Yes',
            handler: () => {
              resolve(true); // Разрешить промис с значением true при нажатии на кнопку "Да"
            }
          },
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              resolve(false); // Разрешить промис с значением false при нажатии на кнопку "Нет"
            }
          },
        ]
      });

      await alert.present();
    });
  }

  openMenu() {
    this.menu.open();
  }

  async showToast(
    header: string,
    text: string,
    type: 'default' | 'warning' | 'success' | 'danger' = 'default',
    duration = 2000   // to set to infinite: null or 0
  ) {
    const buttons = [
      {
        icon: 'assets/icons/close.svg',
        role: 'cancel'
      }
    ];

    const toast = await this.toastController.create({
      header,
      message: text,
      duration,
      cssClass: `toast-type-${type} app-toast-wrapper`,
      translucent: false,
      position: 'bottom',
      mode: 'ios',
      buttons,
    });

    await toast.present().then(() => {
      toast.onDidDismiss();
    });
  }
}
