import {Entity} from '../core/main.entity';
import {Product} from './product.entity';

export class CartProduct extends Entity implements Entity {
  id: number;
  title: string;
  ean: string;
  reference: string;
  cover: string;
  unitPrice: number;
  unitPriceWt: number;
  quantity: number;
  totalPrice: number;
  totalPriceWt: number;

  static getBaseProductObject(product: Product, quantity: number) {
    // const props = Object.keys(CartProduct.prototype);
    const p: CartProduct = new CartProduct();

    // console.log(Object.getOwnPropertyNames(p));

    // props.forEach((item) => {
    //   if (typeof product[item] !== 'undefined') {
    //     p[item] = product[item];
    //   }
    // });

    p.id = product.id;
    p.title = product.title;
    p.reference = product.reference;
    p.cover = product.cover;

    p.unitPriceWt = product.priceWt;

    p.totalPrice = product.price;
    p.totalPriceWt = product.priceWt;
    p.quantity = quantity;

    return p;
  }
}
