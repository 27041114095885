import {Entity} from '../core/main.entity';
import {Type} from 'class-transformer';

import {CartProduct} from './cartProduct.entity';
import {Product} from './product.entity';

export class CartSummary extends Entity implements Entity {
  @Type(() => CartProduct)
  products: CartProduct[] = [];

  shipping: number;
  shippingWt: number;
  totalProduct: number;
  totalProductWt: number;
  totalQuantity = 0;
  total: number;
  totalWt: number;

  fillPrices() {
    this.totalProduct = this.products.reduce((total, item) => total + item.totalPrice, 0);
    this.totalProductWt = this.products.reduce((total, item) => total + item.totalPriceWt, 0);
  }
}
