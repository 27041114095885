import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormControl, FormGroup, NgForm, NgModel} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() control = new FormControl();
  @Input() labelText;
  @Output() checkboxEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCheckboxEvent(){
    if (this.control.value === true) {
      this.control.setValue(false);
    } else {
      this.control.setValue(true);
    }
  }
}
