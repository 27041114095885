import {Injectable, OnDestroy} from '@angular/core';
import {CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {HelperService} from '../../services/helper.service';
import {AppService} from '../../services/app.service';
import {NavigationService} from '../../services/navigation.service';
import {AuthService} from '../../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  appService: AppService;
  authService: AuthService;
  helperService: HelperService;
  navigationService: NavigationService;
  router: Router;

  // @TODO merge two guards

  constructor(
    appService: AppService,
    helperService: HelperService,
    authService: AuthService,
    navigationService: NavigationService,
    router: Router
  ) {
    this.appService = appService;
    this.authService = authService;
    this.helperService = helperService;
    this.navigationService = navigationService;
    this.router = router;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree | any> {
    this.navigationService.entryUrl = state.url.replace(/\?(.*)$/gm, '');
    // Получаем чистый URL, убирая начальный слэш
    const pageLink = this.navigationService.entryUrl.replace('/', '').split('/')[0] ;

    // Список ссылок, доступных для всех
    const freeLinks = ['start', 'auth', 'sign-up'];

    // Инициализируем переменную для хранения элемента меню. Если текущая страница
    // входит в список свободных ссылок, устанавливаем значение в null.
    // В противном случае, ищем элемент меню с соответствующей ссылкой.
    const menuItem = freeLinks.includes(pageLink) ? null :
      this.appService.menuItems.find(item => item.link === pageLink || item.linksInside?.includes(pageLink));

    // Проверяем, есть ли у пользователя права доступа на основе роли.
    // Если текущая страница входит в список свободных ссылок, или у пользователя есть
    // соответствующие права, role будет true, иначе - false.
    const role =
      freeLinks.includes(pageLink) ||
      (menuItem?.permissions?.includes(this.appService.user.role) &&
      menuItem?.linksInside?.includes(pageLink));

    // Если нет прав доступа, перенаправляем на страницу 'start' и выводим сообщение об отсутствии доступа.
    if (!role) {
      this.navigationService.goTo('start');
      this.appService.showToast('No access', 'You do not have access to this page', 'warning');
    }

    if (!this.authService.isLogin()) {
      this.navigationService.goAfterLogin(false);
    }

    return this.authService.isLogin();
  }
}
