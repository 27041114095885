import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: 'item.html',
  styleUrls: ['item.scss']
})
export class ButtonComponent implements OnInit {
  @Input() icon = '';
  @Input() textButton = '';
  @Input() color: 'blue' | 'light-blue' | 'gray' | 'white'  = 'blue';
  // small скорее всего не понадобится, но оставил на всякий случай
  @Input() small = false;
  @Input() disabled = false;
  @Input() cartButton = false;
  @Input() ngxPrint = false;
  @Input() styleSheetFile = '';
  @Input() printSectionId = '';
  @Input() loading = false;

  @Output() clickButton: EventEmitter<void> = new EventEmitter();

  ngOnInit() {}

  getIconUrl() {
    return this.icon ? `/assets/icons/${this.icon}.svg` : null;
  }

  onClick() {
    this.clickButton.emit();
  }

}
