import {Entity} from '../core/main.entity';
import {Type} from 'class-transformer';
import {CustomerGroup} from './customerGroup.entity';
import {formatDate} from '@angular/common';
import {Country} from './country.entity';

export class Address extends Entity {
  id: string;
  address1: string;
  address2: string;
  city: string;
  company: string;
  customerId: number;
  firstName: string;
  homePhone: string;
  lastName: string;
  mobilePhone: string;
  other: string;
  zip: string;

  @Type(() => Country)
  country: Country = new Country();

  get getCountryTitle() {
    return this.country?.title;
  }
}
