import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {InputComponent} from './form/input/item.component';
import {CheckboxComponent} from './form/checkbox/checkbox.component';
import {ButtonComponent} from './ui/button/item';
import {SearchComponent} from './ui/search/search.component';
import {MenuComponent} from './ui/menu/menu.component';
import {BackComponent} from './ui/back/back.component';
import {SelectDropDownComponent} from './ui/select-dropdown/select-dropdown';
import {ClickOutsideModule} from 'ng-click-outside';
import {LoaderComponent} from './ui/loader/item';
import {NgxPrintModule} from 'ngx-print';

@NgModule({
  declarations: [
    InputComponent,
    CheckboxComponent,
    ButtonComponent,
    SearchComponent,
    MenuComponent,
    BackComponent,
    SelectDropDownComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ClickOutsideModule,
    NgxPrintModule
  ],
  exports: [
    InputComponent,
    CheckboxComponent,
    ButtonComponent,
    SearchComponent,
    MenuComponent,
    BackComponent,
    SelectDropDownComponent,
    LoaderComponent
  ]
})

export class ComponentsModule {
}
