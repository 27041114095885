import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from './services/app.service';
import {MainComponent} from './core/main.component';
import {NavigationService} from './services/navigation.service';
import {Subscription} from 'rxjs';
import {environment} from '../environments/environment';
import {CartModelService} from './models/cart.model.service';
import {CustomersModelService} from './models/customers.model.service';
import {MenuItemsModelService} from './models/menuItems.model.service';
import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends MainComponent implements OnInit, OnDestroy {

  customersModelService: CustomersModelService;
  menuItemsModelService: MenuItemsModelService;

  loadStepCount = 0;

  constructor(
    appService: AppService,
    navigationService: NavigationService,
    cartModelService: CartModelService,
    customersModelService: CustomersModelService,
    menuItemsModelService: MenuItemsModelService,
    private authService: AuthService,
  ) {
    super(appService, navigationService, cartModelService);

    this.customersModelService = customersModelService;
    this.menuItemsModelService = menuItemsModelService;

    this.initializeApp();
  }

  initializeApp() {

    // this.navigationService.goTo('start');

    // disable context menu
    // document.addEventListener('contextmenu', event => {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   return false;
    // });

    this.loadMenuItems();
    this.loadUser();
    this.checkLoading();

  }

  loadUser() {
    this.appService.user = this.authService.getToken();
  }

  loadMenuItems() {
    this.menuItemsModelService.getMenuItems((callback) => {});
  }

  onAppLoaded() {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  checkLoading() {
    if (this.loadStepCount <= 0) {
      this.appService.appReady = true;
      this.onAppLoaded();
    }
  }
}
