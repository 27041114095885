import {Entity} from '../core/main.entity';

export class MenuItem extends Entity {
  name = '';
  icon = '';
  link = '';
  access = true;
  dropdownItems?: DropdownItems[];
  dropdownShow? = false;
  permissions: string[];
  linksInside: string[];
}

export class DropdownItems extends Entity {
  link = '';
  title = '';
}
