import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';

const routes: Routes = [
  // {
  //   path: 'product/:productId',
  //   loadChildren: () => import('./pages/product/page.module').then(m => m.ProductPageModule)
  // },
  // {
  //   path: 'cart',
  //   loadChildren: () => import('./pages/cart/page.module').then(m => m.CartPageModule)
  // },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/page.module').then(m => m.HomePageModule)
  // },
  // {
  //   path: 'code',
  //   loadChildren: () => import('./pages/search-by-code/page.module').then(m => m.SearchByCodePageModule)
  // },
  // {
  //   path: 'payment',
  //   loadChildren: () => import('./pages/payment/page.module').then(m => m.PaymentPageModule)
  // },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('./pages/settings/page.module').then(m => m.SettingsPageModule)
  // },
  {
    path: 'carts',
    loadChildren: () => import('./pages/carts/page.module').then(m => m.CartsPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'cart/:cartId',
    loadChildren: () => import('./pages/cart/page.module').then(m => m.CartPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'address',
    loadChildren: () => import('./pages/address-edit/page.module').then(m => m.AddressEditPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/page.module').then(m => m.ProductsPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'product/:productId',
    loadChildren: () => import('./pages/product/page.module').then(m => m.ProductPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/page.module').then(m => m.OrdersPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'order/:orderId',
    loadChildren: () => import('./pages/order/page.module').then(m => m.OrderPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'customer/edit',
    loadChildren: () => import('./pages/customer-edit/page.module').then(m => m.CustomerEditPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'customer/edit/:customerId',
    loadChildren: () => import('./pages/customer-edit/page.module').then(m => m.CustomerEditPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'customer/:customerId',
    loadChildren: () => import('./pages/customer/page.module').then(m => m.CustomerPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/page.module').then(m => m.CustomersPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'delays',
    loadChildren: () => import('./pages/delays/page.module').then(m => m.DelaysPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'packingtool',
    loadChildren: () => import('./pages/packingtool/page.module').then(m => m.PackingToolPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'packingtool/session/:id',
    loadChildren: () => import('./pages/packingtool-session/page.module').then(m => m.PackingToolSessionPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'images',
    loadChildren: () => import('./pages/images/page.module').then(m => m.ImagesPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'images/:imageId',
    loadChildren: () => import('./pages/image/page.module').then(m => m.ImagePageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'tools',
    loadChildren: () => import('./pages/tools/page.module').then(m => m.ToolsPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'financial-dashboard',
    loadChildren: () => import('./pages/financial-dashboard/page.module').then(m => m.FinancialDashboardPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'grids',
    loadChildren: () => import('./pages/grids/page.module').then(m => m.GridsPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'grids/:gridId',
    loadChildren: () => import('./pages/grid/page.module').then(m => m.GridPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'export',
    loadChildren: () => import('./pages/export/page.module').then(m => m.ExportPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/page.module').then(m => m.StartPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/sign-in/page.module').then(m => m.AuthPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/auth/sign-up/page.module').then(m => m.SignUpPageModule)
  },
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
