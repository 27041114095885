import {Entity} from '../core/main.entity';
import {Type} from 'class-transformer';

import {CartSummary} from './cartSummary.entity';
import {CustomerCart} from './customerCart.entity';
import {Address} from './address.entity';
import {Carrier} from './carrier.entity';

export class Cart extends CustomerCart implements Entity {
  id = 0;
  paid = false;
  comment: string;

  selectedShippingId: number;
  selectedPaymentId: number;

  @Type(() => CartSummary)
  summary: CartSummary = new CartSummary();

  @Type(() => CartPromo)
  promo = new CartPromo();

  @Type(() => Address)
  shippingAddress = new Address();

  @Type(() => Address)
  invoiceAddress = new Address();

  @Type(() => CartOptions)
  options = new CartOptions();

}

export class CartPromo extends Entity {
  code: string = null;
  type: PromoType = null;
  description: string = null;
  title: string = null;
}

export class CartOptions extends Entity {
  @Type(() => CartShippingOptions)
  shipping: CartShippingOptions[] = [];

  @Type(() => CartPaymentOptions)
  payment: CartPaymentOptions[] = [];
}

export class CartShippingOptions extends Carrier {
  price: number;
  priceWt: number;
  delay: string; // days
  delivery: string; // date
}

export class CartPaymentOptions extends Entity {
  id: number;
  title: string;
}

export enum PromoType {
  invalid = 0,
  product = 1,
  price = 2,
  delivery = 3
}

export enum CartSyncType {
  sync = 'sync',
  cart = 'cart',
  final = 'final',
  order = 'order'
}
