import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
@Component({
  selector: 'app-loader',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent {
  @Input() size: 'default' | 'small' | 'tiny' = 'default';
  constructor() {}
}
