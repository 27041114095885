import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NavController} from '@ionic/angular';
import {filter} from 'rxjs/operators';
import {MenuItem} from '../entities/menuItems.entity';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  router: Router;
  location: Location;
  navController: NavController;
  appService: AppService;
  public previousUrl;
  public currentUrl;
  entryUrl: string | null;

  constructor(
    router: Router,
    location: Location,
    navController: NavController,
    appService: AppService,
  ) {
    this.router = router;
    this.location = location;
    this.navController = navController;
    this.appService = appService;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url.replace(/^\/+/g, '');
    });
  }

  isCurrentUrl(url) {
    return this.currentUrl?.includes(url);
  }

  goTo(url: string, opt = {}, replaceUrl = false, data = {}) {
    this.router.navigate([url], {queryParams: opt, state: data, replaceUrl});
  }

  // goToUrl(url: any) {
  //   if (typeof url === 'string' || url instanceof String) {
  //     url = [url];
  //   }
  //
  //   this.router.navigate(url);
  // }

  goAfterLogin(loggedIn) {
    if (loggedIn) {
      let entryUrl = this.entryUrl ? this.entryUrl : 'start';

      if (this.appService.pushData) {
        entryUrl = this.appService.pushData.page;
      }

      if (entryUrl.includes('auth')) {
        entryUrl = 'start';
      }

      this.goTo(entryUrl, {}, true);
    } else {
      this.goTo('auth', {}, true);
    }
  }

  goToMenuItem(item: MenuItem) {
    if (!item?.dropdownItems?.length) {
      if (item.link.includes('https://www.livea.fr')) {
        return window.open(item.link);
      } else {
        this.goTo(item.link);
      }
    }
  }

  goBack() {
    this.navController.back();
  }
}
