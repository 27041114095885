import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {RestModelService} from '../core/restModel.service';
import {MenuItem} from '../entities/menuItems.entity';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsModelService extends RestModelService<MenuItem> {
  constructor(
    http: HttpClient,
    appService: AppService
  ) {
    super(http, appService, MenuItem);

    this.model = 'menu-items';
  }

  getMenuItems(callback: (menuItems: MenuItem[]) => void) {
    // временно добавлен искуственный лоадер, в дальнейшем
    // лоадер будет скрываться после ответа с сервера
    this.appService.showLoading().then(() => {
      setTimeout(() => {
        this.appService.hideLoading();
      }, 400);
    });
    // пока закоментировано, т. к. бек ещё не готов
    // this.getItems(1, 100, {}, {}, (response, pagination) => {
    //   console.log('menuItems', menuItems);
    //   callback(response);
    // });
    // массив с элементами меню
    const items = [
      {
        name: 'Catalog',
        icon: 'catalog',
        link: 'products',
        access: true,
        permissions: ['admin', 'superAdmin'],
        linksInside: ['products', 'product']
      },
      {
        name: 'Customers',
        icon: 'customers',
        link: 'customers',
        access: true,
        permissions: ['admin', 'superAdmin'],
        linksInside: ['customers', 'customer']
      },
      {
        name: 'Orders',
        icon: 'orders',
        link: 'orders',
        access: true,
        permissions: ['admin', 'superAdmin'],
        linksInside: ['orders', 'order']
        // закоментировано, для тестов
        // dropdownItems: [
        //   { link: 'https://www.livea.fr/export/atelier/', title: 'Atelier Stats' },
        //   { link: 'https://www.livea.fr/export/gb_export/', title: 'GB Export' },
        //   { link: 'https://www.livea.fr/export/akaaz/', title: 'Akaaz Export' },
        //   { link: 'https://www.livea.fr/export/grohe/grohe.php', title: 'Grohe Export' },
        //   { link: 'https://www.livea.fr/export/order_status/', title: 'Order Statuses Export' },
        //   { link: 'https://www.livea.fr/export/product_stat/', title: 'Product Stats Export' }
        // ],
        // dropdownShow: false
      },
      {
        name: 'Delays',
        icon: 'delays',
        link: 'delays',
        access: true,
        permissions: ['admin', 'superAdmin'],
        linksInside: ['delays']
      },
      {
        name: 'Packing tool',
        icon: 'packing-tool',
        link: 'packingtool',
        access: true,
        permissions: ['admin', 'user', 'superAdmin'],
        linksInside: ['packingtool']
      },
      {
        name: 'Image generator',
        icon: 'image-generator',
        link: 'images',
        access: true,
        permissions: ['admin', 'superAdmin'],
        linksInside: ['images']
      },
      {
        name: 'Tools',
        icon: 'tools',
        link: '',
        access: true,
        permissions: ['admin', 'superAdmin'],
        dropdownItems: [
          { link: 'https://www.livea.fr/export/atelier/', title: 'Atelier Stats' },
          { link: 'https://www.livea.fr/export/gb_export/', title: 'GB Export' },
          { link: 'https://www.livea.fr/export/akaaz/', title: 'Akaaz Export' },
          { link: 'https://www.livea.fr/export/grohe/grohe.php', title: 'Grohe Export' },
          { link: 'https://www.livea.fr/export/order_status/', title: 'Order Statuses Export' },
          { link: 'https://www.livea.fr/export/product_stat/', title: 'Product Stats Export' },
          { link: 'https://www.livea.fr/export/connect/imp.php', title: 'Mirror Connect' }
        ],
        dropdownShow: false
      },
      {
        name: 'Financial dashboard',
        icon: 'financial-dashboard',
        link: 'financial-dashboard',
        access: true,
        permissions: ['admin', 'superAdmin', 'finDashboardUser'],
        linksInside: ['financial-dashboard']
      },
      {
        name: 'Export',
        icon: 'export',
        link: 'export',
        access: true,
        permissions: ['admin', 'user', 'superAdmin'],
        linksInside: ['export']
      },
      {
        name: 'Grids',
        icon: 'grids',
        link: 'grids',
        access: true,
        permissions: ['admin', 'superAdmin'],
        linksInside: ['grids', 'grid']
      }
    ];
    const response = {
      status: true,
      pagination: null,
      message: '',
      result: items
    };
    this.appService.menuItems = response.result;
  }
}
