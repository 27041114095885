import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class InputComponent implements OnInit {
  @Input() label = '';
  @Input() control = new FormControl();
  @Input() inputId = '';
  @Input() type = 'text';
  @Input() password= false;
  @Input() autofocus = false;
  @Input() required = false;
  @Input() readonly = false;
  @Input() error = false;
  @Input() styles = '';

  errorMessages: Record<string, string> = {
    required: 'this field is required',
    email: 'incorrect email',
  };

  constructor(
  ) { }

  ngOnInit() {
  }

  hideShowPass() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
