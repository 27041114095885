import {KeyValue} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  // ngFor keyvalue sorting pipe by the original order
  sortByOriginalOrderPipe = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }
}
