// import * as pcj from '../../../package.json';

export const environment = {
  production: true,
  baseUrl: 'https://api.bathroom2kitchen.co.uk/',
  publicUrl: '',
  apiUrl: 'v1/',
  siteUrl: 'https://bathroom2kitchen.co.uk/'
  // version: pcj.version,
};
