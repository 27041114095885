import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private settings: any;

  constructor() {
    this.getSettings();
  }

  getFromLocal(key: string, defaultValue: string = null) {
    const value = localStorage.getItem(key);

    return value !== 'null' && value !== 'undefined' && value ? value : defaultValue;
  }

  saveToLocal(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  isReady() {
    return true;
  }

  isBlocked() {
    return this.settings.blocked;
  }

  getSettings(): any {

    // const settings = new KioskSettings();
    const settings = {};

    // settings.testMode = this.getFromLocal('testMode', '0') === '1';
    // settings.blocked = this.getFromLocal('blocked', '0') === '1';
    // settings.kioskId = this.getFromLocal('kioskId');
    // settings.deviceId = this.getFromLocal('deviceId', '1');
    // settings.kkmUrl = this.getFromLocal('kkmUrl', 'https://localhost:5893/');
    // settings.kkmLogin = this.getFromLocal('kkmLogin', '');
    // settings.kkmPassword = this.getFromLocal('kkmPassword', '');

    this.settings = settings;

    return settings;
  }

  saveSettings(settings?: any) {
    if (settings) {
      this.settings = {...this.settings, ...settings};
    }
    // this.saveToLocal('testMode', this.settings.testMode ? '1' : '0');
    // this.saveToLocal('blocked', this.settings.blocked ? '1' : '0');
    // this.saveToLocal('kioskId', this.settings.kioskId);
    // this.saveToLocal('deviceId', this.settings.deviceId);
    // this.saveToLocal('kkmUrl', this.settings.kkmUrl);
    // this.saveToLocal('kkmLogin', this.settings.kkmLogin);
    // this.saveToLocal('kkmPassword', this.settings.kkmPassword);

  }
}
