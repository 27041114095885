import {Injectable} from '@angular/core';
import {NavigationService} from './navigation.service';
import {MenuItem} from '../entities/menuItems.entity';

@Injectable({ providedIn: 'root' })
export class MenuService {
  menuItemsToShow = [];
  isOpen = false;

  constructor(
    private navigationService: NavigationService
  ) {
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  result(items?: []) {
    this.menuItemsToShow = items;
  }

  goTo(item: MenuItem, index?: number) {
    if (!item.access) {
      return;
    }
    if (!item?.dropdownItems?.length) {
      this.navigationService.goToMenuItem(item);
      this.isOpen = false;
    } else {
      this.menuItemsToShow[index].dropdownShow = !this.menuItemsToShow[index].dropdownShow;
    }
  }

  showMenu(index: number) {
    if (this.menuItemsToShow[index]?.dropdownItems?.length) {
      this.menuItemsToShow[index].dropdownShow = true;
    }
  }

  hideMenu(index: number) {
    if (this.menuItemsToShow[index]?.dropdownItems?.length) {
      this.menuItemsToShow[index].dropdownShow = false;
    }
  }

  isMenuActive(item: MenuItem) {
    if (item.dropdownItems?.length) {
      return item.dropdownItems.some((i) => this.navigationService.isCurrentUrl(i.link));
    } else {
      return this.navigationService.isCurrentUrl(item.link);
    }
  }

  goToDropDownItemLink(link: string) {
    return window.open(link);
  }
}
