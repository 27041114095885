import { Entity } from '../core/main.entity';

export class AuthToken extends Entity {
    id: number;
    userId: number;
    value: string;
    role: string;
    dateCreated: string;
    dateExecuted: string;
    dateExpires: string;

    public isValid(): boolean {
        try {
            const expires = new Date(this.dateExpires);
            const current = new Date();

            return expires > current;
        } catch (e) {
            console.error(e);
        }

        return false;
    }
}
