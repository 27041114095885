import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {RestModelService} from '../core/restModel.service';
import {Sort} from '../entities/sort.entity';
import {Cart, CartSyncType} from '../entities/cart.entity';
import {CustomerCart} from '../entities/customerCart.entity';
import {Product} from '../entities/product.entity';
import {CartProduct} from '../entities/cartProduct.entity';
import {ApiResponse} from '../entities/apiResponse.entity';
import {CancelHttpService, CancelHttpType} from '../services/cancel-http.service';

@Injectable({
  providedIn: 'root'
})
export class CartModelService extends RestModelService<CustomerCart> {

  updateQuantity$: EventEmitter<any> = new EventEmitter<any>();
  cart: Cart = new Cart();
  syncTimerDelay = 5 * 1000;
  syncTimer = null;
  private cancelHttpService: CancelHttpService;

  // settingsService: SettingsService;
  //
  // eventCreateOrder: Subject<ApiAnswer> = new Subject<ApiAnswer>();
  // createOrder$ = this.eventCreateOrder.asObservable();

  constructor(
    http: HttpClient,
    appService: AppService,
  ) {
    super(http, appService, CustomerCart);
    // this.settingsService = settingsService;
    this.model = 'carts';
  }

  getSortItems(): Sort[] {
    return [
      {
        id: 'date_asc',
        title: 'Date Created ASC',
        field: 'dateCreated',
        direction: 'asc',
      },
      {
        id: 'date_desc',
        title: 'Date Created DESC',
        field: 'dateCreated',
        direction: 'desc',
      }
    ];
  }

  getSortObject() {
    return new Sort(this.getSortItems()[0].id, this.getSortItems()[0].title, this.getSortItems()[0].field, this.getSortItems()[0].direction);
  }

  newCart() {
    this.stopSyncTimer();

    this.cart = new Cart();
    this.updateQuantity$.emit(null);
  }

  addToCart(
    product: Product,
    quantity = +1,
    syncTime = null,
    syncLoading = false,
    callbackSync?: (status: boolean) => void,
  ) {

    const step: CartSyncType = CartSyncType.sync;

    let index = this.findProductIndex(product.id);

    console.log(index);
    console.log(quantity);

    if ((index === -1) && quantity > 0) {

      const p = CartProduct.getBaseProductObject(product, quantity);
      console.log(p);
      this.cart.summary.products.push(p);
      index = this.cart.summary.products.length - 1;
    }

    console.log(this.cart);
    console.log(index);

    let newQuantity = 0;

    if (this.cart?.summary.products[index]) {
      newQuantity = this.cart.summary.products[index].quantity + quantity;

      if (newQuantity <= 0) {

        this.cart.summary.products.splice(index, 1);
        newQuantity = 0;
        this.cart.summary.totalQuantity += quantity;

      } else {
        this.cart.summary.products[index].quantity = newQuantity;

        this.cart.summary.products[index].totalPrice = newQuantity * this.cart.summary.products[index].unitPrice;
        this.cart.summary.products[index].totalPriceWt = newQuantity * this.cart.summary.products[index].unitPriceWt;
      }

      if (newQuantity > 0) {
        let newTotal = this.cart.summary.totalQuantity + quantity;
        if (newTotal <= 0) {
          newTotal = 0;
        }
        this.cart.summary.totalQuantity = newTotal;
      }

      this.cart.summary.fillPrices();


    }

    this.startSyncTimer(syncTime, syncLoading, callbackSync, step);
    this.updateQuantity$.emit(null);
    return newQuantity;

  }

  findProductIndex(productId: number) {
    return this.cart?.summary.products?.findIndex((item) => item.id === productId);
  }

  getQty(productId) {

    if (this.cart) {
      const index = this.findProductIndex(productId);

      if (this.cart?.summary.products[index]) {
        return this.cart?.summary.products[index].quantity;
      }
    }

    return 0;
  }


  sync(step: CartSyncType, callback: (status: boolean) => void = null, loading = false): Promise<ApiResponse> {
    this.stopSyncTimer();

    console.log(this.cart);

    return this.postRequest(this.cart, {}, (answer: ApiResponse) => {

      // if (answer.status) {
      //   this.newCart();
      // } else {
      //   /*
      //   * @TODO обработчик ошибок
      //   * */
      //   this.appService.showAlert('cart error', answer.message);
      // }
      // this.eventCreateOrder.next(answer);
      // if (callback) {
      //   callback(answer);
      // }

    }, 'sync', false, loading);
  }

  stopSyncTimer() {
    if (this.syncTimer) {
      clearTimeout(this.syncTimer);
      this.syncTimer = null;
    }
  }

  startSyncTimer(syncTime = null, syncLoading = false, callbackSync?, step: CartSyncType = CartSyncType.sync) {

    this.stopSyncTimer();
    // this.cancelHttpService.cancel(CancelHttpType.cart);

    this.syncTimer = setTimeout(() => {
      this.sync(step, (status) => {
        if (callbackSync) {
          callbackSync(status);
        }
      }, syncLoading);
    }, syncTime === null ? this.syncTimerDelay : syncTime);
  }

}
