import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'src/app/entities/menuItems.entity';
import {AppService} from '../../../services/app.service';
import {NavigationService} from '../../../services/navigation.service';
import {AuthService} from '../../../services/auth.service';
import {MenuService} from '../../../services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChild('menuList') menuList;
  appService: AppService;
  navigationService: NavigationService;
  authService: AuthService;
  menuService: MenuService;

  isHovered = -1;
  homeItem = {
    name: 'Home',
    icon: '/assets/icons/home.svg',
    link: 'start',
    access: true
  };

  constructor(
    appService: AppService,
    navigationService: NavigationService,
    authService: AuthService,
    menuService: MenuService
  ) {
    this.appService = appService;
    this.navigationService = navigationService;
    this.authService = authService;
    this.menuService = menuService;
  }

  ngOnInit() {
    this.menuService.menuItemsToShow = this.appService.menuItems;
  }

  dropdownPosition(item: HTMLElement) {
    const menuListEl = document.querySelector('.menu-list');
    if (item && menuListEl) {
      const menuItemRect = item.getBoundingClientRect();
      const menuListRect = menuListEl.getBoundingClientRect();
      const top = menuItemRect.top - menuListRect.top;
      return `top: ${top + 40}px`;
    }
  }

  logout() {
    this.authService.logout();
  }
}
