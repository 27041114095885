import {formatDate} from '@angular/common';

export class Entity {

  getFormattedDate?(date: string, format = 'yyyy-MM-dd') {
    try {
      return date ? formatDate(date, format, 'fr-FR') : '';
    } catch (e) {
      console.error(e);

      return '';
    }
  }
}
