import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {RestModelService} from '../core/restModel.service';
import {Product} from '../entities/product.entity';
import {Sort} from '../entities/sort.entity';
import {Customer} from '../entities/customer.entity';

@Injectable({
  providedIn: 'root'
})
export class CustomersModelService extends RestModelService<Customer> {
  selectedCustomer: Customer;

  constructor(
    http: HttpClient,
    appService: AppService
  ) {
    super(http, appService, Customer);

    this.model = 'customers';
  }

  getSortItems(): Sort[] {
    return [
      {
        id: 'date_asc',
        title: 'Date Created ASC',
        field: 'dateCreated',
        direction: 'asc',
      },
      {
        id: 'date_desc',
        title: 'Date Created DESC',
        field: 'dateCreated',
        direction: 'desc',
      }
    ];
  }

  getSortObject() {
    return new Sort(this.getSortItems()[0].id, this.getSortItems()[0].title, this.getSortItems()[0].field, this.getSortItems()[0].direction);
  }

  saveCustomer(item: Customer, callback: (customer: Customer) => void, failedCallback: (info: any) => void) {
    this.saveItem(item.id ? item.id.toString() : '', item, (customer) => {
      callback(<Customer>customer);
    }, (info) => {
      failedCallback(info);
    });
  }
}
