import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ApiResponse} from '../entities/apiResponse.entity';
import {environment} from 'src/environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable, ObservableInput, throwError} from 'rxjs';
import {AuthToken} from '../entities/authToken.entity';
import {NavigationService} from './navigation.service';
import {AppService} from './app.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private httpClient: HttpClient;

  constructor(
    httpClient: HttpClient,
    private navigationService: NavigationService,
    private appService: AppService
  ) {
      this.httpClient = httpClient;
  }

  public getToken(): AuthToken {
      const token = new AuthToken();
      const tokenValue = localStorage.getItem('appAuthToken') || '';
      const tokenExpires = localStorage.getItem('appAuthTokenExpires') || '';
      const tokensRoles = {
        '2b3ebca136f133f2b1546ae5e82156ea': 'admin',
        '2b3ebca136f133asd121ccc56ezxc22a': 'superAdmin',
        '2b3ebc4rwvbas1531s123df82152h6ea': 'user',
        '2b3ebc4rwvbas15i2jfnszke1123df8215a': 'finDashboardUser'
      };
      token.role = tokensRoles[tokenValue];
      token.value = tokenValue;
      token.dateExpires = tokenExpires;

      return token;
  }

  isLogin(): boolean {
      return !!localStorage.getItem('appAuthToken');
  }

  public setToken(token: AuthToken): void {
      localStorage.setItem('appAuthTokenExpires', token.dateExpires);
      localStorage.setItem('appAuthToken', token.value);
  }

  public logout() {
    localStorage.removeItem('appAuthTokenExpires');
    localStorage.removeItem('appAuthToken');
    this.navigationService.goTo('auth');
  }

  public authorize(
      username: string,
      password: string,
      onOk: (response: ApiResponse) => void,
      onFailed: (error: HttpErrorResponse) => void = null
  ): void {

      this.httpClient.post<ApiResponse>(this.getUrl(), { username, password })
      .pipe(catchError(
          (error: any, caught: Observable<ApiResponse>): ObservableInput<ApiResponse> => {
              onFailed(error);

                return throwError(error);
            }
        ))
        .subscribe(response => {
            if ( response ) {
              onOk(response);
              if (response.status) {
                this.appService.user = this.getToken();
              }
            } else {
                if ( typeof onFailed === 'function' ) {
                    onFailed((<any>response));
                }
            }
        });
    }

  private getUrl(): string {
      return environment.baseUrl + environment.apiUrl + 'auth';
  }
}
